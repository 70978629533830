<template>
  <div> 
		<div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:0px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Product" value="" name="voucher">          
        </div>
      </div>
    </div>
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">Image</th>
          <th scope="col">Product Name</th>
          <th scope="col">Short Description</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
        </div>
        <tr v-for="column in tableItems"
            :key="column._id">
					<td class="text-center">
						<img class="img-thumbnail" width="70px" :src="column.simple_products[0].images !== undefined ? column.simple_products[0].images[0].image : column.simple_products[0].image" /> </td>
          <td>{{ column.product_name }}</td>
          <td>{{ column.highlight }}</td>
          <td class="product-action-style">
            <button type="button" @click="submit(column)" v-if="column.btn_status === undefined" class="btn btn-sm btn-primary">Add</button>
            <button type="button" @click="remove(column)" v-if="column.btn_status === 'remove'" class="btn btn-sm btn-danger">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row text-right">
      <div class="col-sm-12">
      <div class="mt-3">
        <b-pagination 
        	v-model="currentPage"
        	:per-page="pageSize"
        	:total-rows="rowcount"
        	align="right">
				</b-pagination>
      </div>
      </div>
    </div>
  </div>
</template>
<script>

import mixinProduct from "@/mixins/merchant/APIProduct";
import APIFlashDeal from "@/mixins/merchant/APIFlashDeal";

export default {
  mixins: [mixinProduct, APIFlashDeal],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Dashboard",
          href: "/merchant-dashbord",
          active: false
        },
        {
          text: "Promotion List",
          href: "/merchant-promotions-list",
          active: false
        },
        {
          text: "Promotion Configure To Product",
          href: "#",
          active: true
        }
      ],
      tableArray: [],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      blockLoading: true
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getPromotionCategories(this.$router.history.current.params.id);
  },
  methods: {
    submit: async function(object) {
      try {
        let ob = {};
        ob.product = object.product_id._id;
        ob.simple_product = object._id;
        ob.flash_deal = this.$router.history.current.params.id;
        let res = await this.addProductToFlashDetail(ob);
        this.getPromotionCategories(ob.flash_deal);
      } catch (error) {
        this.$toast.error("Failed to Add Product!", "Error", {
          position: "topCenter"
        });
      }
    },
    remove: async function(object) {
      try {
        let val = "";
        let res1 = await this.getFlashDealById(this.$router.history.current.params.id);
        for (let x = 0; x < res1.products.length; x++) {
          if (res1.products[x].product === object.product_id._id) {
            val = res1.products[x]._id;
          }
        }
        let res = await this.removeProductFromFlashDetail(val);
        this.getPromotionCategories(this.$router.history.current.params.id);
      } catch (error) {
        this.$toast.error("Failed to Remove Product!", "Error", {
          position: "topCenter"
        });
      }
    },
    getPromotionCategories: async function(id) {
      try {
        let res = await this.getFlashDealById(id);
        if (res.categories !== []) {
          this.tableArray = await this.getCategoryListInPromotion(res.categories, res.min_discount, res.min_price, res.min_qty);
          for (let x = 0; x < this.tableArray.length; x++) {
            this.tableArray[x].product_name = this.tableArray[x].product_id.product_name;
            this.tableArray[x].highlight = this.tableArray[x].product_id.highlight;
            this.tableArray[x].simple_products = this.tableArray[x].image;
          }
          if (res.products.length !== 0) {
            for (let q = 0; q < res.products.length; q++) {
              for (let w = 0; w < this.tableArray.length; w++) {
                if (res.products[q].simple_product === this.tableArray[w]._id) {
                  this.tableArray[w].btn_status = "remove";
                }
              }
            }
          }
          this.tableItems = this.tableArray;
          this.blockLoading = false;
        }
      } catch (error) {
        throw error;
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.tableArray.slice(0, pagesize);
        this.rowcount = this.tableArray.length;
      } else {
        this.tableItems = this.tableArray
          .filter(obj => {
            {
              return (
                (obj.product_name &&
                  obj.product_name.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.highlight &&
                  obj.highlight.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.tableArray.length;
      } 
    },   
    pagination: function(pagesize, currentPage) {
      
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     

      this.tableItems = this.tableArray.slice(startsize, endsize);

    }
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.product-action-style {
  width: 245px;
} 

.sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 5;
  }

  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }

  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color:#5779ae;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }

  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }

  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  #preview-area {
      width: 60px;
      height: 60px;
      position: absolute;
      /* top: 0; */
      left: 0;
      /* bottom: 60px; */
      right: 0;
      margin: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
</style>

 